@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 20 14.3% 4.1%;

        --card: 0 0% 100%;
        --card-foreground: 20 14.3% 4.1%;

        --popover: 0 0% 100%;
        --popover-foreground: 20 14.3% 4.1%;

        --primary: 24 9.8% 83%;
        --primary-foreground: 20 14.3% 4.1%;

        --secondary: 60 4.8% 95.9%;
        --secondary-foreground: 24 9.8% 10%;

        --muted: 60 4.8% 95.9%;
        --muted-foreground: 25 5.3% 44.7%;

        --accent: 60 4.8% 95.9%;
        --accent-foreground: 24 9.8% 10%;

        --destructive: 0 84.2% 60.2%;
        --destructive-foreground: 60 9.1% 97.8%;

        --border: 20 5.9% 90%;
        --input: 20 5.9% 90%;
        --ring: 24 5.4% 63.9%;

        --radius: 0.5rem;
    }

    .dark {
        --background: 20 14.3% 4.1%;
        --foreground: 60 9.1% 97.8%;

        --card: 20 14.3% 4.1%;
        --card-foreground: 60 9.1% 97.8%;

        --popover: 20 14.3% 4.1%;
        --popover-foreground: 60 9.1% 97.8%;

        --primary: 60 9.1% 97.8%;
        --primary-foreground: 24 9.8% 10%;

        --secondary: 12 6.5% 15.1%;
        --secondary-foreground: 60 9.1% 97.8%;

        --muted: 12 6.5% 15.1%;
        --muted-foreground: 24 5.4% 63.9%;

        --accent: 12 6.5% 15.1%;
        --accent-foreground: 60 9.1% 97.8%;

        --destructive: 0 62.8% 30.6%;
        --destructive-foreground: 60 9.1% 97.8%;

        --border: 12 6.5% 15.1%;
        --input: 12 6.5% 15.1%;
        --ring: 24 5.4% 63.9%;
    }
}

body {
    background-color: hsl(var(--background));
    color: hsl(var(--foreground));
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}